export const INTERESTS_IMPORT_SAMPLE_FILE_LOCATION: string =
  'https://s3-us-west-2.amazonaws.com/public.rasa.io/imports_sample/interests_import_sample.csv'

export const SkipHeader: HeaderChoice = {
  key: 'skip',
  description: '* Skip Import *',
  alternatives: [],
}
export interface HeaderChoice {
  alternatives?: string[]
  description: string,
  key: string,
}
export const AllHeaders: HeaderChoice[] = [
  SkipHeader,
  {
    key: 'email',
    description: 'Email',
    alternatives: [
      'emailaddress', 'email',
    ],
  },
  {
    key: 'interests',
    description: 'Interests',
    alternatives: [
      'interests',
      'Interests',
    ],
  },
]

export const INTERESTS = 'interests'

export const UploadPageDetails = {
  interests: {
    button: 'Import',
    description: 'Import interests of your contacts',
    goBack: false,
    icon: 'Add',
    header: 'Interests',
    sample: true,
  },
}

export const  ImportHistoryPageDetails = {
  interests: {
    button: 'Import History',
    description: 'Import History of your contacts',
    goBack: false,
    icon: 'Add',
    header: 'ImportHistory',
    sample: true,
  },
}
