import { DropDownList } from '@progress/kendo-react-dropdowns'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { HeaderComponent } from 'components/header/component'
import { UpgradeAnchorLink } from 'components/link'
import { SettingsIntegrations } from 'components/settings-editor/newsletter/settingsIntegrations'
import { RasaContext } from 'context'
import * as DateFormat from 'date-fns'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Dataset } from 'generic/dataset'
import { updateContactStatus, validateEmail } from 'generic/utility'
import * as GA from 'google-analytics'
import { partition } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import * as PapaParse from 'papaparse'
import pluralize from 'pluralize'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import * as Router from 'router'
import { FileSelector } from 'shared/components'
import { Roles } from 'shared/constants'
import { sendConsent } from 'shared/data-layer/consent'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { uploadFile } from 'shared/utils'
import { ADD } from 'shared_server_client/constants'
import { isOverContactLimit } from 'shared_server_client/types/billing_plan'
import { enterpriseUsage, UsageStats } from 'shared_server_client/types/usage_stats'
import { UploadedContactsComponent } from './afterUploadComponent'
import * as Constants from './constants'
import './styles.css'
import { INTERESTS } from '../add-interests/constants'

export class AddContactsComponentToConnect extends Component<any, any> {
  public static contextType = RasaContext;

  constructor(props: any) {
    super(props)
  }

  public render() {
    return (
      <div className="add-contacts">
        <UploadCSVComponent uploadType={ADD} push={this.props.push} />
      </div>
    )
  }

}

export const AddContactsComponent = connect(
  null,
  {
    push: Router.push,
  },
)(AddContactsComponentToConnect)

export class ReplaceAllContactsComponentToConnect extends Component<any, any> {
  public static contextType = RasaContext;

  constructor(props: any) {
    super(props)
  }

  public render() {
    return (
      <div className="add-contacts">
        <UploadCSVComponent uploadType={ADD} isFullListReplacement={true} push={this.props.push} />
      </div>
    )
  }

}

export const ReplaceAllContactsComponent = connect(
  null,
  {
    push: Router.push,
  },
)(ReplaceAllContactsComponentToConnect)

export class AddContactsViaIntegrationToConnect extends Component<any, any> {
  public static contextType = RasaContext;

  constructor(props: any) {
    super(props)
  }

  public render() {
    return (
      <div className="add-contacts">
        <div className="go-back" onClick={() => this.goBack()}>&lt; &nbsp; Go back</div>
        <SettingsIntegrations />
      </div>
    )
  }
  private goBack = () => {
    this.props.push(`${DashboardMenuOption.contacts}/add-contacts`)
  }
}

export const AddContactsViaIntegration = connect(
  null,
  {
    push: Router.push,
  },
)(AddContactsViaIntegrationToConnect)

interface ValidationStatus {
  isValid: boolean,
  messages: string[],
}

const validateHeader = (props): boolean => {
  const header = props.state.headers[Number(props.field)]
  if (header) {
    if (header.key !== 'skip') {
      return props.state.headers.filter((h) => h.key === header.key).length < 2
    } else {
      return true
    }
  } else {
    return false
  }
}

const trim = (value: string) => {
  return value ? value.trim() : null
}

const createHeaderClassName = (props, header) => {
  if (header.key === 'skip') {
    return 'skip-header'
  } else {
    return validateHeader(props) ? 'valid-header' : 'invalid-header'
  }
}

const CustomHeaderCell = (props) => {
  const header = props.state.headers[Number(props.field)]
  return (
    <div className={createHeaderClassName(props, header)}>
      <DropDownList
        data={Constants.AllHeaders}
        valid={true}
        textField="description"
        dataItemKey="key"
        value={header}
        validityStyles={false}
        onChange={(e) => props.onSelect(e.target.value, Number(props.field))}
      />
    </div>
  )
}

const PreviewGrid = (props) => {
  let recordsToDisplay = []
  const emailIndex = props.state.headers.findIndex(header => header.key === 'email')
    if (emailIndex !== -1) {
      const emailSet = new Set()
      recordsToDisplay = props.state.records.filter(record => {
        const email = record[emailIndex]
        if (emailSet.has(email)) {
          return false;
        } else {
          emailSet.add(email)
          return true
        }
      })
    }
  return (
    <div>
      <div>
        <Grid data={recordsToDisplay.slice(0, 9)} >
          {props.state.headers.map((header, i) => {
            return <GridColumn key={i}
              width={220}
              field={i.toLocaleString()}
              headerCell={props.customHeaderCell}
              cell={props.customCell} />
          })}
        </Grid>
      </div>
    </div>
  )
}

const DuplicatePreviewGrid = (props) => {
  let recordsToDisplay = props.state.records.slice(0, 9)
  if (props.showDuplicates) {
    const emailIndex = props.state.headers.findIndex(header => header.key === 'email')
    if (emailIndex !== -1) {
      const emailSet = new Set()
      recordsToDisplay = props.state.records.filter(record => {
        const email = record[emailIndex]
        if (emailSet.has(email)) {
          return true;
        } else {
          emailSet.add(email)
          return false
        }
      })
    }
  }
  if (recordsToDisplay.length === 0) {
    return null
  } else {
    return (
      <div>
        <h1>Duplicate Record</h1>
        <div>
          <Grid data={recordsToDisplay}>
            {props.state.headers.map((header, i) => {
              const formattedHeader = header.key.replace(/[_\W]+/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
              return <GridColumn key={i}
                                width={220}
                                field={i.toLocaleString()}
                                headerCell={() => (
                                  <div><strong>{formattedHeader}</strong></div>
                                )}
                                cell={(cellProps) => (
                                  <td className={header.key}>{cellProps.dataItem[i]}</td>
                                )} />
            })}
          </Grid>
        </div>
      </div>
    )
  }
}

interface UploadCSVComponentProps {
  uploadType: string,
  isFullListReplacement?: boolean
  push?: (location: string) => any,
}
interface UploadCSVComponentState {
  fileRawHeaders: any[],
  fileCountMsg: string,
  fullHeaders: any[],
  importing: boolean,
  hasHeaders: boolean,
  headers: any[],
  nextClicked: boolean,
  previousClicked: boolean,
  overLimitMessage: string,
  openModal?: boolean,
  isBulkRequestIsPending: boolean,
  isFullListReplacement: boolean,
  isSuperUser: boolean,
  records: any[],
  noEmailCount: number,
  selectedFile: any,
  usageStats: UsageStats,
  uploadType: string
}

export class UploadCSVComponent extends Component<UploadCSVComponentProps, UploadCSVComponentState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  private communityId: string = null
  private emailColumnCountObj: any
  constructor(props: any) {
    super(props)

    this.state = {
      fileCountMsg: '',
      fileRawHeaders: [],
      fullHeaders: [],
      importing: false,
      hasHeaders: true,
      headers: [],
      isBulkRequestIsPending: false,
      isFullListReplacement: false,
      isSuperUser: false,
      nextClicked: false,
      previousClicked: false,
      overLimitMessage: '',
      openModal:false,
      records: [],
      noEmailCount: 0,
      selectedFile: null,
      usageStats: enterpriseUsage,
      uploadType: ADD
    }

    this.reset = this.reset.bind(this)
    this.onFileSelect = this.onFileSelect.bind(this)
    this.selectHeader = this.selectHeader.bind(this)
    this.importContacts = this.importContacts.bind(this)
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.role),
    ]).then(([activeCommunity, role]) => {
        this.communityId = activeCommunity.communityId
        this.setState({ usageStats: activeCommunity.billingInfo.usageStats })
        this.getPendingBulkUploadRuns()
        this.setState({
          isSuperUser: role === Roles.super_admin,
          uploadType: this.props.uploadType,
        })
      })
  }

  public render() {
    const validHeaders = this.validateHeaders()
    const details = Constants.UploadPageDetails[this.state.uploadType]
    return (
      this.state.isBulkRequestIsPending ? <div>{Constants.BULK_UPLOAD_ALREADY_IN_PROCESS_MESSAGE}</div> :
      <div className="import-csv">
        {!this.state.importing ?
          <div className="upload-container">
            {(this.state.nextClicked || details.goBack) &&
              <div className="go-back" onClick={() => this.goBack()}>&lt; &nbsp; Go back</div>
            }
            <HeaderComponent
              icon={this.state.uploadType}
              title={'CONTACTS'}
              subTitle={details.header}
              description={[details.description]}
            />
            {!this.state.records.length &&
              <>
                {this.state.uploadType === ADD ? (
                  <FileSelector sampleFileLocation={Constants.SUBSCRIBERS_IMPORT_SAMPLE_FILE_LOCATION}
                                sampleInterestsFileLocation={Constants.INTERESTS_IMPORT_SAMPLE_FILE_LOCATION}
                                showInterestsFile={this.state.isSuperUser}
                    onSelect={this.onFileSelect}>
                  </FileSelector>
                ) : (
                    <FileSelector sampleFileLocation={Constants.SUBSCRIBERS_IMPORT_SAMPLE_FILE_LOCATION}
                                  onSelect={this.onFileSelect}>
                    </FileSelector>
                )}
              </>
            }
            <div className="file-count">{this.state.fileCountMsg}</div>
            {!!this.state.overLimitMessage &&
              <div className="over-limit-message-container">
                <span className="over-limit-message">{this.state.overLimitMessage}</span>
                <UpgradeAnchorLink source={GA.UpgradeSource.CsvContactLimit}>
                  Upgrade my plan so I can import more contacts
                </UpgradeAnchorLink>
              </div>}
            {this.state.previousClicked && !!this.state.records.length && this.state.nextClicked &&
                <>
                    <div className="d-flex flex-column w-100 mw-350 pb-2">
                      <div className="headers-checkbox-wrapper">
                          <p className="headers-checkbox-text font-weight-bold m-0 pb-2"> My file contains headers</p>
                          <input type="checkbox" className="headers-checkbox ml-auto"
                                 checked={this.state.hasHeaders}
                                 onChange={() => this.toggleHeadersCheckBox()}/>
                      </div>
                    </div>
                    <div className="next-wrapper">
                        <Button outline
                                onClick={() => this.goBack()}
                        >Previous</ Button>
                        <Button outline
                                onClick={() => this.setState({nextClicked: false})}
                        >Next</ Button>
                    </div>
                </>
            }
            {!this.state.nextClicked && !!this.state.records.length &&
                <div>
                    <div className="import-grid-container">
                        <PreviewGrid state={this.state} customHeaderCell={this.customHeaderCell}
                                     customCell={this.customCell}
                                     importContacts={this.importContacts} validHeaders={validHeaders}
                        ></PreviewGrid>
                    </div>
                    <br/><br/>
                    <div className="import-grid-container">
                        <DuplicatePreviewGrid state={this.state} customHeaderCell={this.customHeaderCell}
                                        customCell={this.customCell}
                                        importContacts={this.importContacts} validHeaders={validHeaders}
                                        showDuplicates={true}
                        ></DuplicatePreviewGrid>
                    </div>
                    <br/><br/>
                  {this.renderReplacementContactModal()}
                    <div className='next-wrapper'>
                    <Button outline
                            className="import-button"
                            onClick={this.goBack}
                    >Previous</ Button>
                    <Button outline
                            className="import-button"
                            onClick={() => this.importContacts()}
                            disabled={this.uploadIsDisabled()}
                    >{details.button}
                    </ Button>
                    {this.state.uploadType === ADD &&
                    <Button outline
                      className="import-button"
                      onClick={() => this.onFullReplacementClick()}
                      disabled={this.uploadIsDisabled()}
                      >Replace All
                    </ Button>}
                    </div>
                </div>
            }
            {!this.state.records.length &&
                <div className="next-wrapper">
                    <Button outline
                            onClick={() => this.setState({nextClicked: true, previousClicked: true}, this.loadFile)}
                            disabled={!this.state.selectedFile}
                    >Next</ Button>
                </div>}
            <div className="headers-validation-messages-wrapper">
              {!!this.state.records.length && !validHeaders.isValid
                && validHeaders.messages.map((message, i) =>
                  <span key={i} className="headers-validation-message">{message}</span>)}
            </div>

          </div>
          :
          <UploadedContactsComponent contactsCount={this.state.records.length}
            isFullListReplacement={this.state.isFullListReplacement}
            uploadType={this.state.uploadType} />
        }
      </div>
    )
  }

  private getPendingBulkUploadRuns = () => {
    return new Dataset().loadCommunityDataset('newsletterRuns', this.communityId,
      [{param: 'runType', value: 'contact'}, {param: 'status', value: 'Pending'}]).then((pendingRuns) => {
        if (pendingRuns[0].length && new Date() <
          DateFormat.addHours(new Date(pendingRuns[0][0].start_date), 6)) {
          this.setState({
            isBulkRequestIsPending: true,
          })
        }
      })
  }

  private uploadIsDisabled = (): boolean => {
    const toUploadContactsCount = this.state.records.length
    const addingMoreThanAllowed =
      this.state.uploadType === ADD && isOverContactLimit(this.state.usageStats, toUploadContactsCount)
    return !toUploadContactsCount || !this.validateHeaders().isValid || addingMoreThanAllowed
  }

  private goBack = () => {
    if (this.state.nextClicked) {
      this.reset()
    } else {
      this.props.push(`${DashboardMenuOption.contacts}/add-contacts`)
    }
  }

  private customHeaderCell = (props) => {
    return <CustomHeaderCell
      {...this.props}
      field={String(props.field)}
      onSelect={this.selectHeader}
      state={this.state} />
  }

  private customCell = (props) => {
    const header = this.state.headers[props.columnIndex].key
    return <td className={header === 'skip' ? 'skipped' : 'not-skipped'}>
      {props.dataItem[props.columnIndex]}
    </td>
  }

  private selectHeader(selectedHeader, columnIndex) {
    const newHeaders = [...this.state.headers]
    newHeaders.splice(columnIndex, 1, selectedHeader)
    this.setState({
      headers: newHeaders,
    })
  }

  private getEmailColumnsCountObj(records) {
    return records.reduce((acc, cur) => {
      cur.forEach((value, i) => {
        if (validateEmail(value)) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          acc[i] ? (acc[i] += 1) : (acc[i] = 1)
        }
      })
      return acc
    }, {})
  }

  private getEmailColumnIndex(records) {
    this.emailColumnCountObj = this.getEmailColumnsCountObj(records)
    if (Object.keys(this.emailColumnCountObj).length) {
      const values: any = Object.values(this.emailColumnCountObj)
      const mostOccurences = Math.max(...values)
      const result = Object.keys(this.emailColumnCountObj)
        .filter((column) => this.emailColumnCountObj[column] === mostOccurences)
      return result
    } else {
      return null
    }
  }

  private reset() {
    this.setState({
      fileCountMsg: '',
      importing: false,
      hasHeaders: true,
      headers: [],
      nextClicked: false,
      previousClicked: false,
      records: [],
      noEmailCount: 0,
      isFullListReplacement: false,
      selectedFile: null,
      overLimitMessage: '',
      openModal: false,
      uploadType: ADD
    })
  }

  private onFileSelect(f: any) {
    if (f) {
      this.setState({
        selectedFile: f,
      })
    }
  }

  private loadFile() {
    if (this.state.selectedFile) {
      const reader = new FileReader()
      reader.onload = (e: any) => {
        const data = this.arrayBufferToString(e.target.result)
        if (!isNil(data) && !isEmpty(data)) {
          const parseResult = PapaParse.parse(data)
          const { csvHeaders, csvRecords } = this.splitHeadersAndRecords(parseResult)
          const headerIndex = csvHeaders.findIndex((h) => h.key === 'email')
          const partitionedRecords = partition(csvRecords, (record: any) => this.containsEmail(record, headerIndex))
          const invalidRecordsCount = partitionedRecords[1].length ? partitionedRecords[1].length - 1 : 0
          const finalRecords = partitionedRecords[0]
            .map((record: any) => this.state.uploadType === ADD ?
              record.map((v) => trim(v)) :
              this.removeNeedless(record))
          const finalHeaders = this.state.uploadType === ADD ? csvHeaders : this.removeNeedless(csvHeaders)
          const interestsExists = csvHeaders.some(h => h.key === INTERESTS);
          if(interestsExists){
            this.setState({
              uploadType: INTERESTS
            });
          }
          this.setState({
            headers: finalHeaders,
            fullHeaders: csvHeaders,
            noEmailCount: invalidRecordsCount,
            records: finalRecords,
          }, () => {
            const addingMoreThanAllowed =
              this.state.uploadType === ADD && isOverContactLimit(this.state.usageStats, finalRecords.length)
            this.setState({
              fileCountMsg: this.generateMessage(invalidRecordsCount),
              overLimitMessage: addingMoreThanAllowed ? Constants.OVER_CONTACT_LIMIT_IMPORT_MSG : '',
            })
          })
        }
      }
      reader.readAsArrayBuffer(this.state.selectedFile)
    }
  }

  private splitHeadersAndRecords(parseResult) {
    let csvRecords = parseResult.data.slice(1)
    let csvHeaders = Array(csvRecords[0].length).fill('')
    // if 1st row does not contain a valid email address => assume 1st row is the header and format that header
    if (!this.containsEmail(parseResult.data.slice(0, 1)[0])) {
      csvHeaders = this.formatCsvHeaders(parseResult.data.slice(0, 1)[0], csvRecords)
      this.setState({
        hasHeaders: true,
      })
      // else if 1st row does contain a valid email address => assume 1st row is data
    } else {
      csvRecords = parseResult.data.slice(0)
      csvHeaders = this.formatCsvHeaders(csvHeaders, csvRecords)
      this.setState({
        hasHeaders: false,
      })
    }
    return { csvHeaders, csvRecords }
  }

  private removeNeedless(row) {
    const columnsWithAnEmail = Object.keys(this.emailColumnCountObj)
    return row
      .filter((v, i) => columnsWithAnEmail.includes(i.toString()))
      .map((value) => typeof (value) === 'string' ? trim(value) : value)
  }

  private generateMessage(invalidCount) {
    return !this.state.records.length ?
      'Sorry, we cound not find any valid contact.' :
      `We found ${pluralize('valid contact', (this.state.records.length || 0), true)}! ${!invalidCount ? '' :
        `We removed ${pluralize('contact', (invalidCount || 0), true)} without an email address.`}`
  }

  private formatCsvHeaders(csvHeaders, csvRecords) {
    let finalCsvHeaders = Array(csvRecords[0].length).fill('').map((v, i) => csvHeaders[i] ? csvHeaders[i] : v)
    this.setState({
      fileRawHeaders: finalCsvHeaders,
    })

    const emailColumnIndex = this.getEmailColumnIndex(csvRecords)
    let emailColumnHeader = null
    if (emailColumnIndex) {
      emailColumnHeader = finalCsvHeaders[emailColumnIndex[0]].toLowerCase().replace(/\s|-|_/g, '')
    }
    finalCsvHeaders = finalCsvHeaders.map((header) => {
      const trimmedHeader = header.toLowerCase().replace(/\s|-|_/g, '')
      let newHeader: Constants.HeaderChoice = Constants.SkipHeader
      Constants.AllHeaders.forEach((standardHeader) => {
        if (trimmedHeader === emailColumnHeader && standardHeader.key === 'email') {
          newHeader = standardHeader
        } else if (standardHeader.alternatives.includes(trimmedHeader)) {
          newHeader = standardHeader
        }
      })
      return newHeader
    })
    return finalCsvHeaders
  }

  private containsEmail(record: [], emailIndex = -1) {
    return emailIndex > -1 ?
      validateEmail(record[emailIndex]) : !!record.filter((value: string) => validateEmail(value.trim())).length
  }

  private validateHeaders(): ValidationStatus {
    const result = {
      isValid: true,
      messages: [],
    }
    //Email must be present
    if (!this.state.headers.filter((header) => header.key === 'email').length) {
      result.isValid = false
      result.messages.push('Headers must contain \'Email\'')
    }
    // All columns must have a header
    if (this.state.headers.filter((header) => ['', null].includes(header.key)).length) {
      result.isValid = false
      result.messages.push('All columns must have a header')
    }
    // No header value can be duplicated except Skip

    const summary = this.state.headers.reduce((acc, cur) => {
      if (cur.key !== 'skip') {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        acc[cur.key] ? acc[cur.key] += 1 : acc[cur.key] = 1
      }
      return acc
    }, {})
    const occurences: number[] = Object.values(summary)
    if (Math.max(...occurences) !== 1) {
      result.isValid = false
      result.messages.push('Each header must be unique')
    }
    return result
  }

  private toggleHeadersCheckBox() {
    if (this.state.hasHeaders) {
      const newRecords = [...this.state.records]
      const newFirstRow = this.state.headers.map((header) => header.key)
      newRecords.unshift([...newFirstRow])
      this.setState({
        headers: Array(this.state.records[0].length).fill(Constants.SkipHeader),
        records: newRecords,
        hasHeaders: false,
      }, () => this.changeCountMessage())
    } else {
      const newHeaders = this.state.records[0].map((header) => {
        const trimmedHeader = header.toLowerCase().replace(/\s|-|_/g, '')
        let newHeader: Constants.HeaderChoice = Constants.SkipHeader
        Constants.AllHeaders.forEach((standardHeader) => {
          if (standardHeader.alternatives.includes(trimmedHeader)) {
            newHeader = standardHeader
          }
        })
        return newHeader
      })
      this.setState({
        headers: [...newHeaders],
        records: this.state.records.slice(1),
        hasHeaders: true,
      }, () => this.changeCountMessage())
    }
  }

  private onFullReplacementClick() {
    this.setState(prevState => ({
      openModal: true,
    }))
  }

  private importContacts() {
    if (this.state.records.length && this.state.headers) {
      uploadFile(this.communityId, 'csv', `contacts/${this.state.uploadType || 'import'}`, this.state.selectedFile)
        .then((filePath) => {
          const fieldMapping = this.csvFieldMapping()
          const finalRecords = []
          this.state.records.forEach((record) => {
            const subscriberRecord = record.reduce((acc, field, index) => {
              const header = this.state.headers[index] ? this.state.headers[index].key : null
              if (header && header !== 'skip' && field) {
                acc[header] = field
              }
              return acc
            }, {})
            if (subscriberRecord.email !== '') {
              finalRecords.push(updateContactStatus(subscriberRecord, this.state.uploadType))
            }
          })
          this.setState({ importing: !this.state.importing })
          const subscriberType = this.state.uploadType === INTERESTS ? 'interests-upload' : 'subscriber-upload'
          const url: string = AjaxWrapper.getServerUrl() + `/${this.communityId}/${subscriberType}`
          const consentData = this.context.store.getState().import_contacts.modals.importConsent.data
          const fileName = this.state.selectedFile.name
          const consent = {
            type: consentData.consentType,
            value: consentData.consentValue,
          }

          AjaxWrapper.ajax(url, HttpMethod.POST, {
            subscribers: finalRecords,
            uploadType: this.state.uploadType,
            filePath,
            fileName,
            isFullListReplacement: this.state.isFullListReplacement,
            noEmailCount: this.state.noEmailCount,
            fieldMapping
          })
            .then(() => sendConsent(this.communityId, consent))
          this.props.push(`${DashboardMenuOption.contacts}?success=true`)
        })
    }
  }

  private changeCountMessage() {
    this.setState({
      fileCountMsg: `We found
        ${this.state.records.length} ${this.state.records.length > 1 ? 'contacts' : 'contact'}!`,
    })
  }

  private arrayBufferToString(buffer: ArrayBuffer): string {
    try {
      let data = ''
      const bytes = new Uint8Array(buffer)
      let index = 0
      if (bytes[0] === 0xEF && bytes[1] === 0xBB && bytes[2] === 0xBF) {
        // BOM char throws of the code that evaluates CSV headers.  SO make sure to strip it - EFBBBF
        index = 3
      }
      for (; index < bytes.byteLength; index++) {
        data += String.fromCharCode(bytes[index])
      }
      return data
    } catch (err) {
      return null
    }
  }

  private csvFieldMapping(): object {
    const fieldMapping = {}
    const length = Math.min(this.state.fileRawHeaders.length, this.state.headers.length)
    if (this.state.uploadType === ADD || this.state.uploadType === INTERESTS) {
      for (let i = 0; i < length; i++) {
        if (this.state.headers[i].key !== 'skip') {
          fieldMapping[this.state.headers[i].key] = this.state.fileRawHeaders[i];
        }
      }
    } else {
      for (let i = 0; i < this.state.fullHeaders.length; i++) {
        if (this.state.fullHeaders[i].key === 'email') {
          fieldMapping[this.state.fullHeaders[i].key] = this.state.fileRawHeaders[i];
        }
      }
    }
    return fieldMapping
  }

  private renderReplacementContactModal(): JSX.Element {
    return (
      <div>
        <Modal isOpen={this.state.openModal}>
          <ModalBody className="delete-modal">
            <div>
              <h4>Full List Replacement Confirmation</h4>
              <p>Any contact that is not included in your import but is currently in the dashboard will be archived.</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => {
              this.setState({ isFullListReplacement: true, openModal: false }, () => {
                this.importContacts()
              });
            }}>
              Yes
            </Button>
            <Button onClick={() => {
              this.setState({ isFullListReplacement: false, openModal: false });
            }}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
