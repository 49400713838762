export const BULK_UPLOAD_ALREADY_IN_PROCESS = 'BULK_UPLOAD_ALREADY_IN_PROCESS'
export const BULK_UPLOAD_ALREADY_IN_PROCESS_MESSAGE = 'A bulk request is already in process. Please try after current request is complete.'
export const SUBSCRIBERS_IMPORT_SAMPLE_FILE_LOCATION: string =
  'https://s3-us-west-2.amazonaws.com/public.rasa.io/imports_sample/contacts_import_sample.csv'
export const INTERESTS_IMPORT_SAMPLE_FILE_LOCATION: string =
  'https://s3-us-west-2.amazonaws.com/public.rasa.io/imports_sample/interests_import_sample.csv'

export const SkipHeader: HeaderChoice = {
  key: 'skip',
  description: '* Skip Import *',
  alternatives: [],
}
export interface HeaderChoice {
  alternatives?: string[]
  description: string,
  key: string,
}
export const AllHeaders: HeaderChoice[] = [
  SkipHeader,
  {
    key: 'email',
    description: 'Email',
    alternatives: [
      'emailaddress', 'email',
    ],
  },
  {
    key: 'interests',
    description: 'Interests',
    alternatives: [
      'interests',
      'Interests',
    ],
  },
  {
    key: 'first_name',
    description: 'First Name',
    alternatives: [
      'first',
      'firstname',
    ],
  },
  {
    key: 'last_name',
    description: 'Last Name',
    alternatives: [
      'last',
      'lastname',
    ],
  },
  {
    key: 'external_id',
    description: 'ID',
    alternatives: [
      'id',
      'memberid',
      'member_id',
      'contactid',
      'contact_id',
    ],
  },
  {
    key: 'is_subscribed',
    description: 'Subscribed',
    alternatives: [
      'status',
      'subscribed',
      'issubscribed',
    ],
  },
  {
    key: 'phone',
    description: 'Phone',
    alternatives: [
      'phone',
      'phonenumber',
      'mobile',
      'number',
      'cell',
      'cellphone',
    ],
  },
  {
    key: 'address1',
    description: 'Address 1',
    alternatives: ['address', 'mailingaddress', 'mailingaddress1', 'addressline1', 'address1', 'addr', 'addrss'],
  },
  {
    key: 'address2',
    description: 'Address 2',
    alternatives: ['address2', 'addressline2', 'mailingaddress2'],
  },
  {
    key: 'city',
    description: 'City',
    alternatives: [
      'city',
      'town',
      'cty',
      'cty.',
    ],
  },
  {
    key: 'state',
    description: 'State',
    alternatives: [
      'state',
    ],
  },
  {
    key: 'zip',
    description: 'Zip Code',
    alternatives: [
      'zip',
      'zipcode',
    ],
  },
  {
    key: 'country',
    description: 'Country',
    alternatives: [
      'country',
    ],
  },
  {
    key: 'region',
    description: 'Region',
    alternatives: [
      'region',
    ],
  },
  {
    key: 'organization',
    description: 'Organization',
    alternatives: [
      'organization',
      'company',
      'cmpny',
      'org',
    ],
  },
  {
    key: 'position',
    description: 'Position',
    alternatives: [
      'position',
    ],
  },
  {
    key: 'title',
    description: 'Title',
    alternatives: [
      'title',
    ],
  },
  {
    key: 'practice',
    description: 'Practice Area',
    alternatives: [
      'practice',
      'practicearea',
    ],
  },
  {
    key: 'interest',
    description: 'Interest Area',
    alternatives: [
      'interest',
      'interestarea',
    ],
  },
  {
    key: 'list',
    description: 'List',
    alternatives: [
      'list',
      'List',
    ],
  },
  {
    key: 'audience ',
    description: 'Audience ',
    alternatives: [
      'audience ',
      'Audience ',
    ],
  },
  {
    key: 'segment_code',
    description: 'Segment Code ',
    alternatives: [
      'segment_code',
      'segmentcode',
    ],
  },
]

export const ARCHIVE = 'archive'
export const UNSUBSCRIBE = 'unsubscribe'
export const CONSENT_CSV_IMPORT = 'ConsentCsvImport'
export const UploadPageDetails = {
  add: {
    button: 'Import',
    description: 'Import the list of contacts you want to add',
    goBack: true,
    icon: 'Add',
    header: 'Import Contacts',
    sample: true,
  },
  archive: {
    button: 'Archive',
    description: 'Import the list of contacts you want to archive. Note: If you archive a contact and then add them to a subscriber import, then they will be treated as a new contact and resubscribed to your list.',
    goBack: false,
    icon: 'Archive',
    header: 'Archive Contacts',
    sample: true,
  },
  unsubscribe: {
    button: 'Unsubscribe',
    description: 'Import the list of contacts you want to unsubscribe',
    goBack: false,
    icon: 'Unsubscribe',
    header: 'Unsubscribe Contacts',
    sample: true,
  },
  interests: {
    button: 'Import',
    description: 'Import interests of your contacts',
    goBack: false,
    icon: 'Add',
    header: 'Interests',
    sample: true,
  },
}

export const OVER_CONTACT_LIMIT_IMPORT_MSG = 'Unfortunately, you are trying to import more contacts than your plan allows.'
